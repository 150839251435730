import axios from 'axios';

export const getSharedData = async () => {
    try {
        const sharedData = await axios.get(`${window.location.origin}/api/get-shared-data`)
        return sharedData
    } catch (e) {
        console.error(`Shared data fetch failed with error: ${e}`)
        return {
            data: {
                socialMediaLinks: [],
                footerSecondaryLinks: []
            }
        }
    }
}
