<template>
  <DsPageHeader :screen="screen.name" :theme="headerTheme">
    <template #logo>
      <router-link
        to="/"
        data-track-component-name="DsPageHeader"
        data-track-level="1"
        :data-track-text="logoLabel || logoAriaLabel"
      >
        <DsLogo
          :aria-label="logoAriaLabel"
          :label="logoLabel"
        />
      </router-link>
    </template>
    <template #navigation>
      <nav
        v-if="isScreenLarge"
        class="ds-header-navigation"
      >
        <ul
          class="ds-header-navigation__items"
          role="menubar"
        >
          <!-- Primary items -->
          <li
            v-for="(item, index) in primaryItems"
            :key="`primary_${index}`"
            class="ds-header-navigation__items__item"
            role="none"
          >
            <DsAnchorElement
              :url="item.url"
              :nuxt="false"
              :class="`ds-header-navigation__button--${headerTheme}`"
              class="ds-header-navigation__button"
            >
              {{ item.content }}
            </DsAnchorElement>
          </li>
          <!-- Secondary items -->
          <li
            v-for="(item, index) in secondaryItems"
            :key="`secondary_${index}`"
            class="ds-header-navigation__items__item"
            :class="{ 'ds-header-navigation__items__item--flex-grow': index === 0 }"
            role="none">
            <DsAnchorElement
              :url="item.url"
              :open-in-new-window="item.openInNewWindow"
              :nuxt="false"
              :class="`ds-header-navigation__button--${headerTheme}`"
              class="ds-header-navigation__button"
              role="menuitem"
            >
              {{ item.content }}
            </DsAnchorElement>
          </li>
        </ul>
      </nav>
      <PageHeaderVertical
        v-else
        :primary-items="primaryItems"
        :secondary-items="secondaryItems"
      />
    </template>
  </DsPageHeader>
</template>

<script>
import DsPageHeader from 'website-design-system/src/templates/page-header/page-header';
import DsLogo from 'website-design-system/src/atoms/logo/logo';
import DsAnchorElement from '@/components/anchor-element';
import PageHeaderVertical from './page-navigation-vertical'
import { isScreenLarge } from '@/utils/screen';

export default {
  name: 'PageLayoutHeader',
  components: { DsPageHeader, DsLogo, DsAnchorElement, PageHeaderVertical },
  inject: ['screen'],
  props: {
    headerTheme: {
      type: String,
      required: true
    },
    logoLabel: {
      type: String,
      default: ''
    },
    logoAriaLabel: {
      type: String,
      default: ''
    },
    hideSearch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isScreenLarge () {
      return isScreenLarge(this.screen.name)
    },
    // @TODO add to CMS
    primaryItems () {
      return [{
        content: 'Service status',
        url: '/'
      },
      {
        content: 'Maintenance',
        url: '/maintenance-messages'
      },
      {
        content: 'History',
        url: '/incident-history'
      }]
    },
    // @TODO add to CMS
    secondaryItems () {
      return [{
        content: 'Contact Support',
        url: 'https://ca-live.adyen.com/ca/ca/contactUs/supportNumbers.shtml',
        openInNewWindow: true
      },
      {
        content: 'Help center',
        url: 'https://help.adyen.com',
        openInNewWindow: true
      }]
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'website-design-system/src/foundation/tokens/variables';

.ds-header-navigation {
  height: 100%;
  width: 100%;

  &__items {
    align-items: center;
    display: flex;
    height: 100%;

    &__item {
      align-items: center;
      display: flex;
      height: 100%;
      margin-left: $spacing-24;

      &--flex-grow {
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  &__button {
    $this: &;

    align-items: center;
    display: inline-flex;
    font-size: $font-size;
    height: 70px;
    line-height: $line-height;
    padding: $spacing-3 0;
    position: relative;
    transition: box-shadow 0.15s ease-in-out;

    // button focus ring
    &::before {
      border: $border-width-2 $border-style transparent;
      border-radius: $border-radius-6;
      bottom: 0;
      box-sizing: content-box;
      content: '';
      left: -4px;
      position: absolute;
      right: -4px;
      top: 0;
    }

    &__icon {
      flex: none;
      margin-left: $spacing-12;
      transition: transform 0.15s;
    }

    // Hover state
    @media (hover: hover) {
      &:hover {
        box-shadow: inset 0 3px $color-grey-100;
      }
    }

    // Focus state: visible for keyboard navigation
    @supports (contain: none) {
      &:focus-visible {
        &::before {
          border-color: $color-green-200;
        }
      }
    }

    // fallback for browsers that don't support focus-visible and focus-within yet (Safari)
    @supports not (contain: none) {
      &:focus {
        &::before {
          border-color: $color-green-200;
        }
      }
    }

    &--active {
      &#{$this}--light {
        box-shadow: inset 0 3px $color-green-200;
      }

      &#{$this}--dark,
      &#{$this}--green {
        box-shadow: inset 0 3px $color-white;
      }

      #{$this}__icon {
        transform: rotate(180deg);
      }
    }

    &--light {
      color: $color-black;
    }

    &--dark,
    &--green {
      color: $color-white;
    }
  }
}
</style>
