/**
 * GTM Consent configuration script
 * It will add GTM Consent script to the HTML header
 *
 * - Define dataLayer and the gtag function.
 * - Set default consent to 'denied' as a placeholder
 * - Determine actual values based on your own requirements
 */
export default {
  install: () => {
    const gtmConsentScript = document.createElement('script');
    gtmConsentScript.setAttribute('type', 'text/javascript');
    gtmConsentScript.innerHTML = `    
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied'
      });
    `;
    document.head.appendChild(gtmConsentScript);
  }
}