<script>
import { h, resolveComponent } from 'vue'
const getTargetValue = (openInNewWindow) => openInNewWindow ? '_blank' : null;
const getRelValue = (openInNewWindow) => openInNewWindow ? 'noopener' : null;

/**
 * Anchor element
 */

export default {
  name: 'DsAnchorElement',
  props: {
    /**
     * trackingData, to be set as data-track attributes for tracking purpose
     */
    trackingData: { type: Object, default: () => ({}) },
    /**
     * url, href attribute content
     */
    url: { type: String, required: true },
    /**
     * openInNewWindow, when is set to true it will open the link in a new window
     */
    openInNewWindow: { type: Boolean, default: false },
    /**
     * nuxt, when is set to true it will render a vue router link
     */
    nuxt: { type: Boolean, default: false },
    /**
     * prefetch, when is set to true it will nuxt will prefetch the page
     */
    prefetch: { type: Boolean, default: false },
    /**
     * ariaLabel, arial-label attribute content
     */
    ariaLabel: { type: String, default: '' },
    /**
     * download, adds download attribute
     */
    download: { type: Boolean, default: false }
  },
  computed: {
    elementTag () {
      return this.nuxt && !this.openInNewWindow ? resolveComponent('router-link') : 'a';
    },
    elementProperties () {
      const trackingAttrs = Object.keys(this.trackingData).reduce((acc, key) => ({
          ...acc,
          [`data-track-${key}`]: this.trackingData[key]
      }), {});

      return this.nuxt && !this.openInNewWindow ?
        {
          to: this.url,
          prefetch: this.prefetch,
          ...(this.ariaLabel && { ['aria-label']: this.ariaLabel }),
          ...trackingAttrs
        } : {
            href: this.url,
            target: getTargetValue(this.openInNewWindow),
            rel: getRelValue(this.openInNewWindow),
            ...(this.ariaLabel && { ['aria-label']: this.ariaLabel }),
            ...(this.download && { download: '' }),
            ...trackingAttrs
        };
    }
  },
  render () {
    return h(
        this.elementTag,
        this.elementProperties,
        (this.nuxt ? this.$slots.default : this.$slots.default())
    );
  }
};
</script>
