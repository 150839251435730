const elements = new Map();

const handler = event => {
  const doSetTimeout = (callback, args) => {
    setTimeout(() => {
      callback(args);
    }, 250);
  };
  elements.forEach((callback) => {
    doSetTimeout(callback, event.target.innerWidth);
  });
};

/**
 * Resize directive binds a window resize handler on an element.
 * returns the window width
 *
 * @example
 * <template>
 *   <div v-resize="onResize">
 *     ...
 *   </div>
 * </template>
 *
 * <script>
 * import resize from '@/directives/resize';
 *
 * export default {
 *     directives: { resize },
 *     methods: {
 *       onResize (windowWidth) {
 *         console.info(windowWidth);
 *       }
 *     }
 * };
 * </script>
 *
 */

export default {
  beforeMount (el, binding) {
    const context = binding.value;
    // keep only one window resize handler for all directives
    if (elements.size === 0 && typeof window !== 'undefined') {
      window.addEventListener('resize', handler);
    }
    elements.set(el, context);
  },
  unmounted (el) {
    elements.delete(el);
    if (elements.size === 0 && typeof window !== 'undefined') {
      window.removeEventListener('resize', handler);
    }
  }
};
