import axios from 'axios';

export const getGlobalData = async () => {
    try {
        // Query CMS with axios utility
        const globalDataQueryResult = await axios.post(`/api/global-data`)
        // Return query data
        return globalDataQueryResult.data.globalLabelsCollection.items[0]
    } catch (e) {
        return(`Global data fetch failed with error: ${e}`)
    }
}
