import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import { getSharedData } from './plugins/sharedData';
import { getGlobalData } from './plugins/globalData';
import oneTrust from './plugins/oneTrust';
import gtmConsent from './plugins/gtmConsent';
import App from './App.vue';
import router from './router';
import './registerServiceWorker'

const app = createApp(App);
app.use(router);
app.use(oneTrust, { id: process.env.VUE_APP_ONE_TRUST_ID });
app.use(gtmConsent, { id: process.env.VUE_APP_ONE_TRUST_ID });
app.use(
    createGtm({
        id: process.env.VUE_APP_GTM_ID,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_preview: process.env.VUE_APP_GTM_PREVIEW
        },
        debug: process.env.VUE_APP_DEPLOY_ENV !== 'prod'
    })
);
app.config.unwrapInjectedRef = true;
const sharedData = getSharedData();
const globalData = getGlobalData();

Promise.all([sharedData, globalData])
    .then(values => {
        app.config.globalProperties.$sharedData = values[0].data;
        app.config.globalProperties.$labels = values[1];
        app.mount('#app');
    })
    .catch(err => console.error(err));
