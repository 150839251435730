<template>
  <DsPageLayout :theme="computedTheme">
    <template #header="{ headerTheme }">
      <PageLayoutHeader
        :header-theme="headerTheme"
        :logo-label="$labels.logoLabel"
        :logo-aria-label="$labels.logoAriaLabel"
      />
    </template>
    <template #content>
      <router-view />
    </template>
    <template #footer>
      <PageLayoutFooter
        :footer-label="$labels.goTo"
        :footer-link="{ label: $labels.mainWebsiteLabel, url: $labels.mainWebsiteUrl }"
        :footer-social-items="$sharedData.socialMediaLinks"
        :footer-secondary-navigation="$sharedData.footerSecondaryLinks"
      />
    </template>
  </DsPageLayout>
</template>

<script>
import DsPageLayout from 'website-design-system/src/templates/page-layout/page-layout'
import PageLayoutHeader from '@/components/page-layout-header'
import PageLayoutFooter from '@/components/page-layout-footer'

export default {
  components: { DsPageLayout, PageLayoutHeader, PageLayoutFooter },
  watch: {
    $route (to, from) {
      // Trigger `page` load event when routes change
      // Taking into account only the path, not hash or query params changes
      if (to?.path !== from?.path) {
        setTimeout(() => {
          this.trackPage()
        }, 500)
      }
    }
  },
  mounted () {
    // Trigger `page` load event when app loads for the first time
    this.trackPage();
    this.setUpPWAListeners();
  },
  created () {
    document.documentElement.setAttribute('lang', 'en')
  },
  computed: {
    computedTheme () {
      // return this.$route?.matched[0]?.props?.default?.headerTheme ?? 'grey'
      return 'light'
    }
  },
  methods: {
    trackPage () {
      this.$gtm.trackEvent({
        event: 'virtual_page',
        page: {
          path: this.$route?.path,
          full_url: window?.location.href
        },
        site: {
          pwa: {
            standalone: window.matchMedia('(display-mode: standalone)').matches
          }
        }
      })
    }, 
    setUpPWAListeners () {
      window
      .matchMedia('(display-mode: standalone)')
      .addEventListener('change', ({ matches }) => {
        // eslint-disable-next-line no-console
        console.log(`Switched to ${matches?'':'non-'}standalone PWA display mode`);
        // TODO: update the view to show / hide installation promotion call to action
      });
      window.addEventListener('appinstalled', () => {
        // eslint-disable-next-line no-console
        console.log('PWA has been installed!');
        this.$gtm.trackEvent({
          event: 'pwa_installed'
        })
      });
    }
  }
};
</script>

<style lang="scss">
@import 'website-design-system/src/foundation/styles';
@import 'assets/css/styles';
</style>
