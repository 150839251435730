import SCREEN_NAME from '../../constants/screen'

/**
 * Is screen large
 * @param {String} currentScreenName
 * @returns boolean
 */
export const isScreenLarge = currentScreenName => currentScreenName === SCREEN_NAME.large

/**
 * Is screen medium
 * @param {String} currentScreenName
 * @returns boolean
 */
export const isScreenMedium = currentScreenName => currentScreenName === SCREEN_NAME.medium

/**
 * Is screen small
 * @param {String} currentScreenName
 * @returns boolean
 */
export const isScreenSmall = currentScreenName => currentScreenName === SCREEN_NAME.small
