<script>
/**
 * Title with highlighted text
 * It will highlight the text between double curly braces: `{{text to highlight}}`
 *
 * @example
 * ```js
 * import DsHighlightedTitle from '@/elements/highlighted-title/highlighted-title';
 *
 * export default {
 *   components: { DsHighlightedTitle },
 *   template: `<div>
 *     <DsHighlightedTitle
 *       text="This is a title with {{important}} text to remark"
 *     />
 *   </div>`;
 * };
 *```
 */
import { h } from 'vue'
import theme from 'website-design-system/src/utils/mixins/theme/theme'

export default {
    name: 'DsHighlightedTitle',
    mixins: [theme],
    props: {
        /**
         * title text
         */
        text: { type: String, required: true },
        /**
         * heading tag to be rendered
         */
        headingTag: { type: String, default: 'h1', validator: str => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(str) },
        /**
         * title size
         */
        size: { type: String, default: '', validator: str => ['', 'xsmall', 'small', 'medium', 'large', 'xlarge'].includes(str) },
        /**
         * title medium size
         */
        sizeMedium: { type: String, default: '', validator: str => ['', 'xsmall', 'small', 'medium', 'large', 'xlarge'].includes(str) },
    },
    computed: {
        elementProperties () {
            let className = 'ds-heading';
            if (this.size) {
                className = `ds-heading-${this.size}`;
            }
            if (this.sizeMedium) {
                className += ` ds-md-heading-${this.sizeMedium}`;
            }
            return {
                class: className
            };
        },
        highlightedTextColorClass () {
            if (this.theme === this.$options.theme.green) {
                return 'ds-color-black';
            }
            return 'ds-color-green';
        }
    },
    render () {
        const regex = /\{\{(.*?)\}\}/g; // match anything between `{{ }}`
        const partsToHighlight = this.text?.match(regex);

        // Case with no text to highlight
        if (!partsToHighlight) {
            return h(
                this.headingTag,
                this.elementProperties,
                this.text
            );
        }

        // Case with highlighted text
        const result = partsToHighlight.reduce((acc, match) => {
            const [current, ...rest] = acc.remaining.split(match);
            acc.parts.push(
                current,
                h('span', {
                  class: this.highlightedTextColorClass,
                  attrs: { 'data-j-highlight': '' }
                }, match.replace(/{{|}}/g, ''))
            );
            acc.remaining = rest.join(match);
            return acc;
        }, { remaining: this.text, parts: [] });

        const titleParts = result.parts.concat(result.remaining);

        return h(
          this.headingTag,
          this.elementProperties,
          titleParts
        );
    }
};
</script>
