const MIN_NUMBER_OF_ITEMS = 3;

const STATUSES = {
    OPERATIONAL: 'OPERATIONAL',
    DEGRADED_PERFORMANCE: 'DEGRADED_PERFORMANCE',
    SEVERELY_DEGRADED_PERFORMANCE: 'SEVERELY_DEGRADED_PERFORMANCE'
}

const STATUSES_BY_SEVERITY = {
    GREY: 'OPERATIONAL',
    YELLOW: 'DEGRADED_PERFORMANCE',
    RED: 'SEVERELY_DEGRADED_PERFORMANCE'
}

const SYSTEMS = [
    'PLATFORM_AVAILABILITY',
    'ACQUIRER_PAYMENTS_PERFORMANCE',
    'CUSTOMER_AREA_REPORTING',
    'SETTLEMENT_PAYOUT',
    'ADYEN_FOR_PLATFORMS',
    'FINANCIAL_PRODUCTS'
]

const PAGE_STATES = {
    START: 'start',
    LOADING: 'loading',
    ERROR: 'error',
    SUCCESS: 'success'
}

const MESSAGE_TYPE = {
    INCIDENT: 'incident',
    MAINTENANCE: 'maintenance'
}

export {
    MIN_NUMBER_OF_ITEMS,
    STATUSES,
    STATUSES_BY_SEVERITY,
    SYSTEMS,
    PAGE_STATES,
    MESSAGE_TYPE
};
