<template>
  <!-- @TODO implement -->
  <!-- v-click-outside="close" -->
  <!-- @keydown.esc="handleEscapeKey" -->
  <div
    tabindex="-1"
  >
    <button
        type="button"
        aria-haspopup="true"
        aria-controls="navigation"
        :aria-expanded="isNavOpen.toString()"
        :aria-label="isNavOpen ? navigationLables.ariaLabelCloseNavigation : navigationLables.ariaLabelOpenNavigation"
        class="ds-header-navigation-button ds-button-link ds-button-link--primary ds-button-link--black"
        :class="headerNavigationButtonClassName"            
        @click="toggleNav">
        <DsHeaderNavigationIcon :active="isNavOpen" />
    </button>
    <DsHeaderNavigationVerticalSlideOver v-show="isNavOpen" with-shadow>
      <nav id="navigation" :aria-label="navigationLables.ariaLabelNavigation" class="ds-header-navigation">
        <ul
          :aria-label="navigationLables.ariaLabelNavigation"
          class="ds-header-navigation__items"
          role="menubar"
        >
          <!-- Primary items -->
          <li
            v-for="(item, index) in primaryItems"
            :key="`primary_${index}`"
            class="ds-header-navigation__items__item ds-background-color-white ds-padding-y-12"
            :class="[
                { 'ds-padding-top-24': index === 0 },
                { 'ds-padding-bottom-24': index === primaryItems.length - 1 },
                { 'ds-padding-y-12': index > 0 && index < primaryItems.length - 1 }
            ]"
            role="none"
          >
            <DsAnchorElement
              :url="item.url"
              :nuxt="true"
              class="ds-header-navigation__link"
              role="menuitem"
              @click="toggleNav"
            >
              {{ item.content }}
            </DsAnchorElement>
          </li>
          <!-- Secondary items -->
          <li
            v-for="(item, index) in secondaryItems"
            :key="`secondary_${index}`"
            :class="[index === 0 ? 'ds-padding-top-24 ds-padding-bottom-12' : 'ds-padding-y-12']"
            class="ds-header-navigation__items__item"
            role="none"
          >
            <DsAnchorElement
              :url="item.url"
              :open-in-new-window="item.openInNewWindow"
              :nuxt="false"
              class="ds-header-navigation__link"
              role="menuitem"
              @click="toggleNav"
            >
              {{ item.content }}
            </DsAnchorElement>
          </li>
        </ul>
      </nav>
    </DsHeaderNavigationVerticalSlideOver>
  </div>
</template>

<script>
import DsHeaderNavigationIcon from 'website-design-system/src/templates/page-header/components/header-navigation-icon';
import DsHeaderNavigationVerticalSlideOver from 'website-design-system/src/templates/page-header/components/header-navigation-vertical-slide-over'
import DsAnchorElement from './anchor-element'

export default {
  name: 'PageHeaderVertical',
  components: { DsHeaderNavigationIcon, DsHeaderNavigationVerticalSlideOver, DsAnchorElement },
  props: {
    primaryItems: {
      type: Array,
      default: () => ([])
    },
    secondaryItems: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    isNavOpen: false,
    // @TODO add label to CMS or take them from global website (shared data)
    navigationLables: {
      ariaLabelNavigation: 'Navigation',
      ariaLabelOpenNavigation: 'Open Navigation',
      ariaLabelCloseNavigation: 'Close Navigation'
    }
  }),
  methods: {
    toggleNav () {
      if (this.isNavOpen) {
          this.close();
      } else {
          this.isNavOpen = true;
          setTimeout(() => {
              this.$emit('slide-over-opened');
          }, 200); // wait for slide-over animation
          this.$emit('navigation-opened', { label: this.navigationLables.ariaLabelOpenNavigation });
      }
    },
    close () {
      if (!this.isNavOpen) {
        return;
      }
      this.$emit('slide-over-closed');
        this.$nextTick(() => {
        this.isNavOpen = false;
      });
      this.$emit('navigation-closed', { label: this.navigationLables.ariaLabelCloseNavigation });
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'website-design-system/src/foundation/tokens/variables';
@import 'website-design-system/src/foundation/mixins/mixins';

.ds-header-navigation-button {
  align-items: center;
  display: flex;
  height: $height-button;
  justify-content: center;
  position: absolute;
  right: -$spacing-12;
  top: $spacing-12;
  width: $height-button;
  z-index: $z-index-2;
}

.ds-header-navigation {
  height: 100%;
  margin-top: $height-header;
  width: 100%;

  @include screen('medium') {
    width: $spacing-12*35;
  }

  &__items {
    background-color: $color-grey-025;
    height: 100%;
    width: 100%;

    &__item {
      padding-left: $spacing-24;
      padding-right: $spacing-24;
      position: relative;

      @include screen('medium') {
        padding-left: $spacing-48;
        padding-right: $spacing-48;
      }
    }
  }

  &__link {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>