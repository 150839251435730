/**
 * One Trust
 * It will add One Trust script to the HTML header
 */
export default {
  install: (_app, options) => {
    const oneTrustScript = document.createElement('script');
    oneTrustScript.setAttribute('type', 'text/javascript');
    oneTrustScript.setAttribute('data-domain-script', options.id);
    oneTrustScript.setAttribute('data-language', 'en');
    oneTrustScript.setAttribute('src', '//cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    document.head.appendChild(oneTrustScript);

    const oneTrustOptanonScript = document.createElement('script');
    oneTrustOptanonScript.setAttribute('type', 'text/javascript');
    oneTrustOptanonScript.innerHTML = 'function OptanonWrapper() { }';
    document.head.appendChild(oneTrustOptanonScript);
  }
}